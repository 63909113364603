import { useQuery, ApolloClient } from "@apollo/client"
import Tooltip from "@material-ui/core/Tooltip"
import { History, Location } from "history"
import React from "react"
import { AdminSQLQuery, AdminSQLQueryVariables } from "../../../__generated__/AdminSQLQuery"
import { AdminSQLTablesQuery } from "../../../__generated__/AdminSQLTablesQuery"
// import Code from "../../components/Code";
import { fromGlobalId, toGlobalId } from "../../../common/global-ids"
import { emptyArray } from "../../../common/misc-utils"
import { camelCase, upperFirst } from "../../../common/string-utils"
// import settings from "../../../settings";
import { getParam, setParam } from "../../utils/url-utils"
import { ButtonLink } from "../components/AdminLink"
import Layout from "../components/Layout"
import LoadingView from "../components/LoadingView"
import MaterialTable from "../components/material-table"
import { Query as DtQuery } from "../components/material-table/index.d"
import SimpleSelect from "../components/SimpleSelect"
import { ADMIN_SQL_QUERY, ADMIN_TABLES_QUERY, ADMIN_UPSERT_GAME_INSTANCE_MUTATION } from "../queries"
import JsonColumn from "./json-column"

const useSearchForState = false
// const useHistoryForState = false;

const isSearchable = (columns: IDescribeResult[]) => !!searchableField(columns)
const searchableField = (columns: IDescribeResult[]) => columns.find((col) => /searchName|userLogin|id/.test(col.Field))
const isValidSearch = (search: string | null) => !!search && !/SELECT|UPDATE|DELETE|INSERT|DESCRIBE|\(|\)/i.test(search)
function processDbColumnsResponse(arr: IDescribeResult[], tableName: string) {
  if (arr.length && !arr[0].hasOwnProperty("tableName")) {
    const newArr = JSON.parse(JSON.stringify(arr))
    newArr.forEach((el) => (el.tableName = tableName))
    return newArr
  } else {
    return arr
  }
}

// const dataTableAddFunc = (
//   client: ApolloClient<any>,
//   tableName: string,
//   columns: IDescribeResult[],
// ) => async (newData: any) => {
//
// };
const dataTableUpdateFunc = (client: ApolloClient<any>, tableName: string, columns: IDescribeResult[]) => async (newData: any, oldData: any) => {
  // console.log("newData, oldData");
  // console.dir(newData);
  const updates = {} as any
  // console.dir(oldData);
  // const changes = [] as string[];
  Object.keys(newData).forEach((colName) => {
    const col = columns.find((c) => c.Field === colName)
    if (col && !["updatedAt", "createdAt"].includes(colName) && newData[colName] != oldData[colName]) {
      updates[colName] = newData[colName] && newData[colName].toJSON ? newData[colName].toJSON() : newData[colName]
    }
  })

  if (Object.keys(updates).length) {
    if (newData.hasOwnProperty("id")) {
      updates.id = newData.id
    }
    const mapping = {
      [tableName]: updates,
    }
    // const response = await client.mutate({
    //   mutation: ADMIN_UPSERT_GAME_INSTANCE_MUTATION,
    //   variables: {
    //     mapping,
    //   },
    //   refetchQueries: ["ADMIN_SQL_QUERY"],
    //   awaitRefetchQueries: true,
    // });

    await client.mutate({
      mutation: ADMIN_UPSERT_GAME_INSTANCE_MUTATION,
      variables: {
        mapping,
      },
      refetchQueries: ["ADMIN_SQL_QUERY"],
      awaitRefetchQueries: true,
    })

    // console.log("response:");
    // console.dir(response);
    // window.DD = client
    // await client.reFetchObservableQueries()
    // await client.query({
    //   query: ADMIN_SQL_QUERY,
    //   variables: {
    //     statement,
    //     ids,
    //   },
    // });
  }
  return newData
}
// const dataTableDeleteFunc = (
//   client: ApolloClient<any>,
//   tableName: string,
//   columns: IDescribeResult[],
// ) => async (oldData: any) => {
//
// };

const isTextFieldRegex = /varchar|text/
const isQuotedFieldRegex = /enum/

const dataTableQueryFunc = (
  client: ApolloClient<any>,
  tableName: string,
  columns: IDescribeResult[],
  location: Location,
  history: History,
  sqlWhereScopes?: string[],
  virtualColumns?: IDescribeResult[],
  modifyResults?: any,
) => async (query: DtQuery) => {
  // console.log(`query`)
  // console.dir(query);
  const { page, pageSize } = query
  const orderByCol = (query.orderBy && query.orderBy.field) || "id"
  const ids = [] as string[]
  const whereParts = [] as string[]
  const search = query.search
  if (isValidSearch(search)) {
    let isGlobalId = false
    try {
      isGlobalId = !!fromGlobalId(search).id
    } catch (_) {}
    const searchCol = (!isGlobalId && searchableField(columns)) || idField
    if (isGlobalId) {
      ids.push(search)
    }
    if (isQuotedFieldRegex.test(searchCol.Type)) {
      whereParts.push(`${tableName}.${searchCol.Field} LIKE '${search}%'`)
    } else {
      // console.log(2);
      // console.dir(searchCol);
      const val = isQuotedFieldRegex.test(searchCol.Type) ? `'${search}'` : search
      whereParts.push(`${tableName}.${searchCol.Field} = ${val}`)
    }
  }
  query.filters.forEach((filter) => {
    const searchCol = columns.find((col) => col.Field === filter.column.field)
    let wherePart = ""
    if (searchCol) {
      if (isTextFieldRegex.test(searchCol.Type)) {
        wherePart = `${tableName}.${searchCol.Field} LIKE '${filter.value}%'`
      } else if (filter.column?.type === "boolean") {
        wherePart = `${tableName}.${searchCol.Field} = ${filter.value === "checked" ? 1 : 0}`
      } else {
        const val = (isQuotedFieldRegex.test(searchCol.Type) && `'${filter.value}'`) || filter.value
        wherePart = `${tableName}.${searchCol.Field} = ${val}`
      }
    }
    if (isValidSearch(wherePart)) {
      whereParts.push(wherePart)
    }
  })
  const whereString = sqlWhereScopes
    ? whereParts.length
      ? `WHERE ${sqlWhereScopes.join(" AND ")} AND ${whereParts.join(" AND ")}`
      : `WHERE ${sqlWhereScopes.join(" AND ")}`
    : whereParts.length
    ? `WHERE ${whereParts.join(" AND ")}`
    : ""
  const orderBy = `${orderByCol} ${query.orderDirection || "DESC"}`
  const limit = `LIMIT ${pageSize} OFFSET ${pageSize * page}`

  // NOTE fjp: This checks whether or not you can order the data by, since virtual columns do not exist in sql, we have to ignore them when it comes to ordering by
  let canOrderBy = false
  virtualColumns?.forEach((element) => {
    if (element.Field === orderByCol) canOrderBy = true
  })

  const statement = `SELECT * FROM \`${tableName}\` ${whereString} ORDER BY ${canOrderBy ? `id ${query.orderDirection || "DESC"}` : orderBy} ${limit}`

  // console.log("sentence")
  // console.log(statement)
  const response = await client.query({
    query: ADMIN_SQL_QUERY,
    variables: {
      statement,
      ids,
    },
    fetchPolicy: "no-cache",
  })

  const modifyResponse = modifyResults && (await modifyResults(response, client))

  // console.log(`response:`)
  // console.dir(response)
  if (useSearchForState) {
    const newParams = encodeURIComponent(
      JSON.stringify({
        search,
        page,
        pageSize,
        orderBy: query.orderBy,
        orderDirection: query.orderDirection,
      }),
    )
    const currentParams = (getParam(queryQueryParam, location.search) || "").toString()
    if (newParams != currentParams) {
      const newLocation = Object.assign({}, location, {
        search: setParam(location.search, queryQueryParam, newParams),
      })
      history.replace(newLocation)
    }
  }

  const data = JSON.parse(JSON.stringify(modifyResults ? modifyResponse : response.data.sql)) as any[]

  const totalCount = data.length === pageSize ? pageSize * (page + 2) : data.length + pageSize * page
  return {
    data,
    page,
    totalCount,
  }
}

// const getTablesQuery = `SELECT table_name FROM information_schema.tables WHERE table_schema='${settings.DB_DATABASE}';`;
// const listTablesVariables = {statement: getTablesQuery};
const tableNameQueryParam = "table"
const emptyArr = [] as any[]

const getColType = (col: IDescribeResult) => {
  const type = col.Type
  return (
    (type === "tinyint(1)" && "boolean") ||
    ((type.includes("timestamp") || type.includes("datetime")) && "datetime") ||
    (type.includes("int") && "numeric") ||
    "string"
  )
}
const idField = {
  Default: null,
  Extra: "auto_increment",
  Field: "id",
  Key: "PRI",
  Null: "NO",
  Type: "int(10) unsigned",
}

interface IColMap {
  title: string
  field: string
  type: string
  filtering: boolean
  searchable: boolean
  editable: string
  render: (rowData: any) => JSX.Element
  editComponent: (editComponentProps: any) => JSX.Element
}

interface IMaintPagePoolDetails {
  poolId: string
  entryId: string
  columnsToDisplay: string[] // should match col.Field type
}

// NOTE fjp : This is all the functionalities within the datatable and this object is used
// to allow for custom use of these functionalities. Not all pages using datatable want to use the same features
export interface IDataTableColFunctionalities {
  goToId: boolean
  highlightColJson: boolean
  jsonColButton: boolean
  searchCol: boolean
}

const mapCols = (col: IDescribeResult, dataTableColFunctionalities?: IDataTableColFunctionalities, maintPagePoolDetails?: IMaintPagePoolDetails) => {
  const type = getColType(col)
  const { poolId } = maintPagePoolDetails || ({} as never)

  const colMap = {
    title: col.Field,
    field: col.Field,
    type,
    filtering: !/json|date|timestamp/.test(col.Type),
    searchable: type !== "datetime",
  } as IColMap

  if (["id", "createdAt", "updatedAt"].includes(col.Field)) {
    colMap.editable = "never"
  }
  if (col.Field === "id" && dataTableColFunctionalities?.goToId) {
    colMap.render = (rowData) => {
      const gid = toGlobalId(col.tableName, rowData.id)
      return (
        <Tooltip title={gid} placement="bottom" interactive={true}>
          <ButtonLink to={poolId ? `/admin/maint/pool/${poolId}/entry/${gid}` : `/admin/database?id=${gid}`}>{rowData.id}</ButtonLink>
        </Tooltip>
      )
    }
  }
  const foreignParts = col.Field.split("Id")

  // NOTE fjp: I did this part, col.Field.indexOf("Table") < 0, to not allow this functionality for columns like actorTableId and objectTableId
  if (dataTableColFunctionalities?.highlightColJson && foreignParts[1] === "" && col.Field.indexOf("Table") < 0) {
    const fTableName = upperFirst(
      camelCase(foreignParts[0].replace(/away|home|winning|manager|currentScoring|parent|currentSeason|challengeFor/i, "")),
    )

    colMap.render = (rowData) => {
      const fId = rowData[col.Field]
      const globalId = toGlobalId(fTableName, fId)
      // return (
      //   <Tooltip title={globalId} placement="bottom" interactive={true}>
      //     <ButtonLink color="primary" to={`/admin/database?${globalIdQueryParam}=${globalId}`}>{rowData.id}</ButtonLink>
      //   </Tooltip>
      // );
      return (
        <JsonColumn
          buttonText={fId}
          dialogTitle={`${fTableName} : ${fId} - (${globalId})`}
          tableName={fTableName}
          id={fId}
          extraBtn={() => (
            <ButtonLink color="primary" to={`/admin/database?${globalIdQueryParam}=${globalId}`}>
              View
            </ButtonLink>
          )}
        />
      )
    }
  }

  if (dataTableColFunctionalities?.highlightColJson && ["target", "object", "actor"].includes(col.Field)) {
    colMap.render = (rowData) => {
      if (rowData[col.Field] !== null && rowData[col.Field] !== "unknown") {
        const fId = fromGlobalId(rowData[col.Field]).id
        const tableName = fromGlobalId(rowData[col.Field]).type
        const globalId = rowData[col.Field].toString()
        const objectType = rowData[`${col.Field}TableName`]
        return (
          <JsonColumn
            buttonText={globalId}
            dialogTitle={`${tableName} : ${fId} - (${globalId})`}
            tableName={tableName}
            id={+fId}
            fallbackData={rowData.extra && rowData.extra[objectType]}
            extraBtn={() => (
              <ButtonLink color="primary" to={`/admin/database?${globalIdQueryParam}=${globalId}`}>
                View
              </ButtonLink>
            )}
          />
        )
      }
      return <>{rowData[col.Field]}</>
    }
  }

  if (dataTableColFunctionalities && dataTableColFunctionalities.jsonColButton && col.Type === "json") {
    colMap.render = (rowData) => {
      const fId = rowData[col.Field]
      return <JsonColumn data={rowData[col.Field]} dialogTitle={col.Field} tableName={col.tableName} id={fId} />
    }
    colMap.editComponent = (editComponentProps) => {
      // console.log("editComponent");
      // console.dir(editComponentProps);
      return <input type="text" value={JSON.stringify(editComponentProps.value)} onChange={(e) => editComponentProps.onChange(e.target.value)} />
    }
  }
  return colMap
}
const sortCols = (a: IDescribeResult, b: IDescribeResult) => {
  if (a.Field === "id") {
    return -1
  }
  if (b.Field === "id") {
    return 1
  }
  if (a.Type === "json") {
    return 1
  }
  if (b.Type === "json") {
    return -1
  }
  if (/createdAt|updatedAt/.test(a.Field)) {
    return 1
  }
  if (/createdAt|updatedAt/.test(b.Field)) {
    return -1
  }
  if (a.Null === "YES") {
    return 1
  }
  if (b.Null === "YES") {
    return -1
  }
  return a.Field.toString().localeCompare(b.Field.toString())
}

export interface IDescribeResult {
  Default: null | any
  Extra: string
  Field: string
  Key: string
  Null: "YES" | "NO"
  Type: string
  tableName: string
}

const decodedIdQueryParam = "decodedId"
const globalIdQueryParam = "id"
const queryQueryParam = "query"

interface IDataTableProps {
  location: Location<any>
  history: History<any>
  poolId?: string
  tableNameFromProp?: string
  title?: string
  blacklistedColumns?: string[]
  whitelistedColumns?: string[]
  virtualColumns?: IDescribeResult[]
  modifyResults?: any
  sqlWhereScopes?: string[]
  isEditable?: boolean
  isDeletable?: boolean
  excludeLayout?: boolean
  dataTableColFunctionalities?: IDataTableColFunctionalities
  additionalActions?: any[]
}

// sqlStatemtn = `SELECT * FROM ${tableName} WHERE isManager = true ${sqlWhereScopes ? "AND " + sqlWhereScopes.join(" AND ") : ''}`

function DataTable(props: IDataTableProps) {
  const {
    location,
    history,
    tableNameFromProp,
    isEditable,
    isDeletable,
    poolId,
    sqlWhereScopes,
    whitelistedColumns,
    title,
    virtualColumns,
    modifyResults,
    dataTableColFunctionalities,
    additionalActions,
  } = props

  const globalId = decodeURIComponent((getParam(globalIdQueryParam, location.search) || "").toString())
  const decodedIdFromUrl = getParam(decodedIdQueryParam, location.search)
  const decodedGlobalId = globalId && fromGlobalId(globalId)

  const listTablesQuery = useQuery<AdminSQLTablesQuery>(ADMIN_TABLES_QUERY)
  const tableNames = listTablesQuery.data?.listTables || emptyArray

  let tableName = tableNameFromProp || (getParam(tableNameQueryParam, location.search) || (decodedGlobalId && decodedGlobalId.type) || "").toString()

  if (!tableNames.includes(tableName)) {
    tableName = ""
  }
  const options = tableNames.map((name) => ({ value: name, children: name }))
  const describeQueryVariables = {
    statement: `DESCRIBE \`${tableName}\``,
  }

  function updateTable(table: string) {
    const newLocation = Object.assign({}, location, {
      search: `${tableNameQueryParam}=${table}`,
    })
    // console.log(`newLocatio ${table}`, newLocation)
    return history.replace(newLocation)
  }

  let initialQuery = {} as DtQuery
  if (useSearchForState) {
    try {
      const currentQueryString = (getParam(queryQueryParam, location.search) || "").toString()
      if (currentQueryString) {
        initialQuery = JSON.parse(decodeURIComponent(currentQueryString))
      }
    } catch (err) {}
  }
  if (tableName && (decodedIdFromUrl || decodedGlobalId)) {
    initialQuery.search = decodedIdFromUrl || (decodedGlobalId as any)?.id
  }
  const describeQuery = useQuery<AdminSQLQuery, AdminSQLQueryVariables>(ADMIN_SQL_QUERY, {
    variables: describeQueryVariables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: !tableName,
  })
  const dbColumns = processDbColumnsResponse((describeQuery.data?.sql || emptyArr) as IDescribeResult[], tableName)

  let columns: IColMap[]

  if (virtualColumns) virtualColumns.forEach((col) => dbColumns.push(col))

  const defaultDataTableColFunctionalities: IDataTableColFunctionalities = {
    goToId: true,
    highlightColJson: true,
    jsonColButton: true,
    searchCol: true,
  }

  if (poolId) {
    columns = []
    const poolDetailsQuery: IMaintPagePoolDetails = {
      poolId,
      entryId: "",
      columnsToDisplay: whitelistedColumns || [],
    }
    dbColumns
      .concat([])
      .sort(sortCols)
      .forEach((col) => {
        // if we have columnsToDisplay, and the col.Field is one of the ones that we pass in, we want to show it
        const shouldDisplay = poolDetailsQuery.columnsToDisplay.includes(col.Field)
        if (shouldDisplay) {
          columns.push(mapCols(col, dataTableColFunctionalities || defaultDataTableColFunctionalities, poolDetailsQuery))
        }
      })
  } else {
    columns = dbColumns
      .concat([])
      .sort(sortCols)
      .map((col) => {
        return mapCols(col, dataTableColFunctionalities ? dataTableColFunctionalities : defaultDataTableColFunctionalities, undefined)
      })
  }

  const ContainerComponent = props.excludeLayout ? React.Fragment : Layout
  // NOTE fjp: Did this check to get rid of the : Invalid prop `title` supplied to `React.Fragment`. React.Fragment can only have `key` and `children` props error.
  // React.fragments do not have a title prop
  const containerProps = props.excludeLayout ? {} : { title: `Database: ${tableName || "All"} Table` }

  return (
    <ContainerComponent {...containerProps}>
      {(!tableName && <SimpleSelect options={options} value={tableName} update={updateTable} label="Table" name="table" />) || null}
      {/*<Code>{JSON.stringify(tableNames, null, 2)}</Code>*/}
      {(describeQuery.data && (
        <MaterialTable
          title={title || `Table: ${tableName}`}
          columns={columns}
          data={dataTableQueryFunc(describeQuery.client, tableName, dbColumns, location, history, sqlWhereScopes, virtualColumns, modifyResults)}
          options={{
            search: isSearchable(dbColumns),
            paginationType: "stepped",
            pageSize: 5,
            filtering: true,
            actionsColumnIndex: -1,
            exportButton: true,
          }}
          actions={additionalActions || []}
          editable={{
            isEditable: () => isEditable !== false,
            isDeletable: () => isDeletable !== false,
            onRowUpdate: dataTableUpdateFunc(describeQuery.client, tableName, dbColumns),
          }}
          initialQuery={initialQuery}
        />
      )) ||
        (tableName && <LoadingView variant="view" />) ||
        null}
    </ContainerComponent>
  )
}

export default DataTable
